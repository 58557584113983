<!-- @author chengyuzhang -->
<!-- @email chengyuzhang@yangqianguang.com -->
<!-- @date 2020-05-06 17:51:22.157 -->
<!-- @desc generated by yqg-cli@1.2.8-alpha.9 -->

<template>
    <div class="term">
        <div class="term-bg d-none d-md-block" />
        <div class="nav-area-sm d-md-none">
            <div class="sub-routes-sm container">
                <div
                    v-for="(term, key) in $t('term.termsMap')"
                    :key="key"
                    class="sub-route-sm"
                    :class="{'sub-route-sm-active': key === currentSelected}"
                >
                    <router-link
                        :to="`/term/${key}`"
                    >
                        {{ term }}
                    </router-link>
                </div>
            </div>
        </div>
        <div class="term-content container">
            <div class="nav-area d-none d-md-block">
                <div class="nav-area-content">
                    <div class="nav">
                        <div class="side-nav-bar" />
                        <div class="sub-routes">
                            <div
                                v-for="(term, key) in $t('term.termsMap')"
                                :key="key"
                                class="sub-route"
                            >
                                <div
                                    v-if="key === currentSelected"
                                    class="sub-route-active"
                                />
                                <router-link
                                    :to="`/term/${key}`"
                                    class="sub-route-inner"
                                    tag="h6"
                                >
                                    {{ term }}
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="content-area">
                <yqg-md-preview :md-text="contentMap[currentSelected]" />
            </div>
        </div>
    </div>
</template>
<script type="text/babel">
    import Webview from 'easycash/common/resource/Webview';

    export default {

        beforeRouteUpdate(to, form, next) {
            if (!this.contentMap[to.params.key]) {
                this.getContent(to.params.key);
            }

            next();
        },
        data() {
            return {
                contentMap: {
                    OLACRED_TERMS_AND_CONDITIONS: '',
                    OLACRED_PRIVACY_POLICY: ''
                }
            };
        },

        computed: {
            currentSelected() {
                return this.$route.params.key;
            }
        },

        mounted() {
            this.getContent();
        },

        methods: {
            getContent(key = this.$route.params.key) {
                Webview.getStaticAgreementByKey({key}).then(({data: {body: {content}}}) => {
                    this.contentMap[key] = content;
                });
            }
        }
    };
</script>
<style lang="scss" rel="stylesheet/scss">
    @import "~olacred/common/style/olacred";

    .term {
        position: relative;
        height: 100%;
        background: #f4f9fc;

        &-bg {
            height: 100%;
            width: 50%;
            position: absolute;
            background: #fff;
            left: 0;
        }

        &-content {
            display: flex;
        }

        .nav-area {
            width: 30%;
            min-height: 70vh;
            background: $base-white;

            &-content {
                width: 100%;
                height: 100%;
            }
        }

        .nav-area-sm {
            position: sticky;
            top: 60px;
            overflow-x: auto;
            height: 50px;
            z-index: 6;
            background-color: #f0f0f0;

            &::after {
                content: "";
                display: block;
                position: absolute;
                bottom: 0;
                left: 0;
                height: 2px;
                width: 100vw;
                background: #f0f0f0;
            }

            a {
                margin: 0;
                color: rgba(0, 0, 0, 100%);
            }

            .sub-routes-sm {
                display: flex;
                align-items: center;
                width: 100%;
                overflow-x: auto;
                overflow-y: hidden;
                padding-left: 30px;
                -webkit-overflow-scrolling: touch;

                &::-webkit-scrollbar {
                    display: none;
                }
            }

            .sub-route-sm {
                flex-shrink: 0;
                min-width: 30%;
                padding: 0 10px;
                height: 50px;
                line-height: 50px;
                text-align: center;
                position: relative;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0%);

                @media screen and (max-width: $screen-md) {
                    &-active {
                        a {
                            color: #333;
                        }
                    }

                    &-active::after {
                        position: absolute;
                        content: "";
                        width: 100%;
                        height: 2px;
                        bottom: 0;
                        left: 0;
                        z-index: 1;
                        background: #ff734b;
                    }
                }
            }
        }

        .content-area {
            position: relative;
            padding: 60px 0 80px 10%;
            width: 70%;
            background: #f4f9fc;

            @media screen and (max-width: $screen-md) {
                width: 100%;
                padding-top: 30px;
                padding-bottom: 50px;
            }

            h3 {
                margin-bottom: 35px;

                @media screen and (max-width: $screen-md) {
                    font-size: 20px;
                }
            }

            a {
                text-decoration: none;
                color: #000;
            }
        }

        .nav {
            position: sticky;
            top: 72px;
            padding: 72px 0;
            backface-visibility: hidden;
            perspective: 1000px;

            .side-nav-bar {
                position: absolute;
                top: 72px;
                left: 0;
                width: 6px;
                border-radius: 3px;
                height: 140px;
                background-color: #f0f0f0;
            }

            .side-nav-indicator {
                background: linear-gradient(90deg, #5ffe81 0%, #00c997 100%);
            }
        }

        .sub-routes {
            min-width: 1400px;
            height: 140px;

            .sub-route {
                position: relative;
                margin: 0 20px;
                padding: 20px 0;
                user-select: none;

                &-active {
                    position: absolute;
                    top: 0;
                    left: -20px;
                    width: 6px;
                    border-radius: 3px;
                    height: 70px;
                    background: #ff734b;
                }

                &-inner {
                    display: flex;
                    align-items: center;
                    min-height: 30px;
                    line-height: 30px;
                }

                h6 {
                    margin: 0;
                    color: #666;
                    padding-left: 20px;
                }

                .router-link-active {
                    color: #333;
                }
            }
        }
    }
</style>
