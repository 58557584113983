var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "term" }, [
    _c("div", { staticClass: "term-bg d-none d-md-block" }),
    _vm._v(" "),
    _c("div", { staticClass: "nav-area-sm d-md-none" }, [
      _c(
        "div",
        { staticClass: "sub-routes-sm container" },
        _vm._l(_vm.$t("term.termsMap"), function (term, key) {
          return _c(
            "div",
            {
              key: key,
              staticClass: "sub-route-sm",
              class: { "sub-route-sm-active": key === _vm.currentSelected },
            },
            [
              _c("router-link", { attrs: { to: "/term/" + key } }, [
                _vm._v(
                  "\n                    " + _vm._s(term) + "\n                "
                ),
              ]),
            ],
            1
          )
        }),
        0
      ),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "term-content container" }, [
      _c("div", { staticClass: "nav-area d-none d-md-block" }, [
        _c("div", { staticClass: "nav-area-content" }, [
          _c("div", { staticClass: "nav" }, [
            _c("div", { staticClass: "side-nav-bar" }),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "sub-routes" },
              _vm._l(_vm.$t("term.termsMap"), function (term, key) {
                return _c(
                  "div",
                  { key: key, staticClass: "sub-route" },
                  [
                    key === _vm.currentSelected
                      ? _c("div", { staticClass: "sub-route-active" })
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "router-link",
                      {
                        staticClass: "sub-route-inner",
                        attrs: { to: "/term/" + key, tag: "h6" },
                      },
                      [
                        _vm._v(
                          "\n                                " +
                            _vm._s(term) +
                            "\n                            "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "content-area" },
        [
          _c("yqg-md-preview", {
            attrs: { "md-text": _vm.contentMap[_vm.currentSelected] },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }